<template>
  <div class="profile min-h-screen">
    <p class="font-bold text-2xl mb-6">Change Password</p>
    <div class="shadow-card rounded-lg bg-white p-6 mb-6 w-2/5 mx-auto">
      <div class="mb-4">
        <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Current Password</label>
        <div class="relative">
          <TextField :type="inputType1" placeholder="Enter your current password" borderEnabled v-model="payloads.currentPassword" />

          <img
            src="~@/assets/images/open-eye.svg"
            class="absolute right-4 w-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
            v-if="inputType1 === 'password' && isTyping1"
            @click="showPassword('currentPassword', true)"
          />
          <img
            src="~@/assets/images/close-eye.svg"
            class="absolute right-4 w-5 top-1/2 transform -translate-y-1/2"
            v-if="inputType1 === 'text' && isTyping1"
            @click="showPassword('currentPassword', false)"
          />
        </div>
        <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="errorList['currentPassword']">
          {{ errorList['currentPassword'] }}
        </div>
      </div>
      <div class="mb-8">
        <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">New Password</label>
        <div class="relative">
          <TextField :type="inputType2" placeholder="Enter your new password" borderEnabled v-model="payloads.newPassword" />

          <img
            src="~@/assets/images/open-eye.svg"
            class="absolute right-4 w-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
            v-if="inputType2 === 'password' && isTyping2"
            @click="showPassword('newPassword', true)"
          />
          <img
            src="~@/assets/images/close-eye.svg"
            class="absolute right-4 w-5 top-1/2 transform -translate-y-1/2"
            v-if="inputType2 === 'text' && isTyping2"
            @click="showPassword('newPassword', false)"
          />
        </div>
        <div class="pl-3 text-system-error text-left mt-1 text-xs" v-if="errorList['newPassword']">
          {{ errorList['newPassword'] }}
        </div>
      </div>
      <div class="text-right">
        <Button buttonText="Change" @action="submit" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isNotEmptyError } from '@/utils/form-validator'
const CONFIG = {
  currentPassword: 'Current Password',
  newPassword: 'New Password'
}
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  data() {
    return {
      data: null,
      inputType1: 'password',
      inputType2: 'password',
      isTyping1: false,
      isTyping2: false,
      payloads: {
        currentPassword: '',
        newPassword: ''
      },
      errorList: [],
      isChangePasswordSuccess: null
    }
  },
   watch: {
    'payloads.currentPassword': function (val) {
      val.length > 0 ? (this.isTyping1 = true) : (this.isTyping1 = false)
      if (val.length < 6  && this.isChangePasswordSuccess === false) {
        this.errorList['currentPassword'] = 'Minimum 6 characters'
      } else {
        this.errorList['currentPassword'] = ''
      }
    },
    'payloads.newPassword': function (val) {
      val.length > 0 ? (this.isTyping2 = true) : (this.isTyping2 = false)
      if (val.length < 6  && this.isChangePasswordSuccess === false) {
        this.errorList['newPassword'] = 'Minimum 6 characters'
      } else {
        this.errorList['newPassword'] = ''
      }
    }
  },
  // created() {
  //   this.getProfile()
  // },
  computed: {
    ...mapGetters('customer', ['customer'])
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('customer', ['CHANGE_PASSWORD']),
    // getProfile() {
    //   this.$store.dispatch('customer/PROFILE').then(res => {
    //     this.data = res.data.data
    //   })
    // },
    showPassword(passwordType, status) {
      if (passwordType === 'currentPassword') {
        status ? (this.inputType1 = 'text') : (this.inputType1 = 'password')
        return
      }
      status ? (this.inputType2 = 'text') : (this.inputType2 = 'password')
    },
    submit() {
      if (this.validForm()) {
        if (this.payloads.currentPassword.length < 6) {
          this.errorList['currentPassword'] = 'Password must be longer than 6 characters!'
          return false
        }

        if (this.payloads.newPassword.length < 6) {
          this.errorList['newPassword'] = 'Password must be longer than 6 characters!'
          return false
        }
        const payloads = {
          email: this.customer.email,
          currentPassword: this.payloads.currentPassword,
          newPassword: this.payloads.newPassword
        }
        this.CHANGE_PASSWORD({
          payloads
        }).then((res) => {
          if (res.data.code === 200) {
            this.isChangePasswordSuccess = true
              this.payloads.currentPassword = ''
              this.payloads.newPassword = ''
              this.errorList['newPassword'] = ''
              this.errorList['currentPassword'] = ''
              this.successMessage('Your password has been changed successfully!')
              this.$emit('close')
              return
            }
            this.isChangePasswordSuccess = false
            this.errors['newPassword'] = ''
            this.errors['currentPassword'] = ''
            this.errorMessage('Your old password is wrong!')
            this.hideLoading()
        },
          () => {
            this.isChangePasswordSuccess = false
            this.errors['newPassword'] = ''
            this.errors['currentPassword'] = ''
            this.hideLoading()
          })
      }
    },
    validForm() {
      const mandatoryFields = ['currentPassword', 'newPassword']
      const error = isNotEmptyError(this.payloads, mandatoryFields)
      this.errorList = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errorList).length === 0
    },
    successMessage(message) {
      this.$toasted.show(message, {
        position: 'top-center',
        type: 'success',
        duration: 3000,
        theme: 'bubble',
        singleton: true
      })
    },
    errorMessage(message) {
      this.$toasted.show(message, {
        position: 'top-center',
        type: '#FF0000',
        duration: 3000,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
