var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile min-h-screen"
  }, [_c('p', {
    staticClass: "font-bold text-2xl mb-6"
  }, [_vm._v("Change Password")]), _c('div', {
    staticClass: "shadow-card rounded-lg bg-white p-6 mb-6 w-2/5 mx-auto"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Current Password")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.inputType1,
      "placeholder": "Enter your current password",
      "borderEnabled": ""
    },
    model: {
      value: _vm.payloads.currentPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.payloads, "currentPassword", $$v);
      },
      expression: "payloads.currentPassword"
    }
  }), _vm.inputType1 === 'password' && _vm.isTyping1 ? _c('img', {
    staticClass: "absolute right-4 w-5 top-1/2 transform -translate-y-1/2 cursor-pointer",
    attrs: {
      "src": require("@/assets/images/open-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword('currentPassword', true);
      }
    }
  }) : _vm._e(), _vm.inputType1 === 'text' && _vm.isTyping1 ? _c('img', {
    staticClass: "absolute right-4 w-5 top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/close-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword('currentPassword', false);
      }
    }
  }) : _vm._e()], 1), _vm.errorList['currentPassword'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.errorList['currentPassword']) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "mb-8"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("New Password")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.inputType2,
      "placeholder": "Enter your new password",
      "borderEnabled": ""
    },
    model: {
      value: _vm.payloads.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.payloads, "newPassword", $$v);
      },
      expression: "payloads.newPassword"
    }
  }), _vm.inputType2 === 'password' && _vm.isTyping2 ? _c('img', {
    staticClass: "absolute right-4 w-5 top-1/2 transform -translate-y-1/2 cursor-pointer",
    attrs: {
      "src": require("@/assets/images/open-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword('newPassword', true);
      }
    }
  }) : _vm._e(), _vm.inputType2 === 'text' && _vm.isTyping2 ? _c('img', {
    staticClass: "absolute right-4 w-5 top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/close-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword('newPassword', false);
      }
    }
  }) : _vm._e()], 1), _vm.errorList['newPassword'] ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.errorList['newPassword']) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "text-right"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Change"
    },
    on: {
      "action": _vm.submit
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }